import { FC } from "react";

import TeamingUp from "../DefaultComponents/TeamingUp";
import AtticusBenefit from "../DefaultComponents/AtticusBenefit";
import AtticusVideoDemo from "../DefaultComponents/AtticusVideoDemo";
import AtticusValueProps from "../DefaultComponents/AtticusValueProps";
import About from "../DefaultComponents/About";
import AppFeatures from "../DefaultComponents/AppFeatures";
import ResourcesLinks from "../DefaultComponents/ResourcesLinks";
import NewsletterSubscription from "../DefaultComponents/NewsletterSubscription";
import Footer from "../../../layouts/Main/Footer";

const AtticusStatic: FC = () => {
  return (
    <>
      {/* "Atticus & Partner are teaming up" Section */}
      <TeamingUp />

      {/* "Without Atticus, estate settlement and probate are confusing and overwhelming" Section */}
      <AtticusBenefit />

      {/* AtticusVideoDemo "Take Atticus for a spin" Section */}
      <AtticusVideoDemo />

      {/* "Atticus helps Partner members settle their estates." Section  */}
      <AtticusValueProps />

      {/* "Why we love <BANK>" Section */}
      <About />

      {/* "Capture what their loved ones left behind" Section */}
      <AppFeatures />

      {/* "Magazine, Resources, In lieu of flowers" Section */}
      <ResourcesLinks />

      {/* "Get the latest articles from our magazine every month" Section */}
      <NewsletterSubscription />

      <Footer />
    </>
  );
};

export default AtticusStatic;
