import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useCommonStyles = makeStyles((theme: Theme) => ({
  // used in more than one file
  gap48: {
    [theme.breakpoints.down("md")]: {
      gap: "48px",
    },
  },
  title: {
    fontSize: "34px",
    fontWeight: 800,
  },

  mobileHide: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileShow: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },

  sectionDescription: {
    color: "#595A5F", // grey2 is not on the material-ui custom theme
    fontSize: "16px",
  },
  fullWidthButton: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  moreInfoButton: {
    padding: "8px 22px",
  },

  // to clear out

  root: {
    minHeight: "100%",
    // paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    position: "relative",
  },

  firstSection: {
    padding: "104px 26px",
    [theme.breakpoints.up("md")]: {
      padding: "104px 64px",
    },
  },

  subtitle: {
    fontSize: "28px",
    fontWeight: 800,
  },
}));

export default useCommonStyles;
