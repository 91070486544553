/**
 * WARNING: don't use these colors directly in the app. Use the theme.palette
 * instead so that the theme switcher will work properly
 *
 * These colors should only be referenced when initializing the themes: /theme/index.js
 */
const atticusColors = {
  white: "#FFFFFF",
  green1: "#27B8AB",
  green2: "#01D9C6", // TODO: Confirm this change
  purple0: "#B5BFDA",
  purple1: "#643BAE",
  purple2: "#6363FF",
  purple3: "#4E57AA",
  purple4: "#2D2A5C",
  silver1: "#CDCDDB",
  silver2: "#EDEDF4",
  blue1: "#2C2CFE",
  black: "#000000",
  grey1: "#222222",
  grey2: "#393A3D",
  grey3: "#77797E",
  grey4: "#96979C",
  grey5: "#B5B7BA",
  grey6: "#D5D6D7",
  grey7: "#F4F5F5",
  grey8: "#F9FAFA", // not in design specs
  red1: "#FF0000", // not in design specs
  red2: "#FF3333", // not in design specs
};

export default atticusColors;
