import { FC } from "react";

import TeamingUp from "../DefaultComponents/TeamingUp";
import AtticusValueProps from "../DefaultComponents/AtticusValueProps";
import Footer from "../../../layouts/Main/Footer";

const AtticusDynamic: FC = () => {
  return (
    <>
      {/* "Atticus & Partner are teaming up" Section */}
      <TeamingUp />

      {/* "Atticus helps Partner members settle their estates." Section  */}
      <AtticusValueProps />

      <Footer />
    </>
  );
};

export default AtticusDynamic;
