import { colors, createTheme } from "@mui/material";
import { getTypography } from "./typography";
import { getOverrides } from "./overrides";
import atticusColors from "./colors";

const defaultTheme = createTheme();

// NOTE: for recognized properties: https://material-ui.com/customization/default-theme/
const lightPalette = {
  type: "light",
  background: {
    default: atticusColors.grey7,
    paper: colors.common.white,
  },
  primary: {
    light: atticusColors.purple0,
    main: atticusColors.purple3,
    dark: atticusColors.purple4,
    contrastText: colors.common.white,
  },
  secondary: {
    main: atticusColors.green2,
    dark: atticusColors.green1,
  },
  error: {
    main: atticusColors.red2,
    light: atticusColors.red1,
  },
  text: {
    primary: atticusColors.grey1,
    secondary: atticusColors.grey2,
    disabled: atticusColors.grey5,
    hint: atticusColors.grey6,
  },
  divider: atticusColors.grey7,
  common: {
    black: colors.common.black,
    white: colors.common.white,
  },
};

const light = createTheme({
  palette: lightPalette,
  typography: getTypography(lightPalette, defaultTheme),
  components: getOverrides(lightPalette, defaultTheme),
});

export const themes = {
  light,
};
