import React from "react";

interface Props {
  className?: string;
  trademark?: boolean;
}
const logoPrefix = "/static/images/Atticus";

export const Logo = ({ trademark, ...rest }: Props): JSX.Element => {
  if (trademark) {
    return (
      <img
        src={`${logoPrefix}-Trademark.svg`}
        alt="Atticus"
        title="Atticus"
        {...rest}
      />
    );
  } else {
    return (
      <img src={`${logoPrefix}.svg`} alt="Atticus" title="Atticus" {...rest} />
    );
  }
};
