import {
  MarketingApp,
  FormsApp,
  ConsumerApp2,
  enumToStringDictionary,
  CONSTANTS as SHARED_CONSTANTS,
} from "@theatticusapp/atticus-shared-components";
export const ATTICUS_PAGE_PREFIX = "Atticus Partners";
export const ATTICUS_CONSUMER_APP_SIGN_UP = "/auth?signup=true";
export const ATTICUS_MARKETING_LEARN_MORE = "/for-families";

// Used to determine the page variant a given partner wants to display.
export enum PageVariants {
  ATTICUS_STATIC,
  ATTICUS_DYNAMIC,
  ACI,
}

export enum PartnerLogoFormats {
  HORIZONTAL,
  VERTICAL,
}

export enum RedirectOptions {
  MARKETING,
  APP,
  FORMS,
}

export type Partner = {
  key: string;
  path: string;
  title: string;
  inviteId: {
    prod: string;
    dev: string;
  };
  logoFormat: PartnerLogoFormats;
  showDisclaimerModal?: boolean;
  partnerRedirect?: RedirectOptions; // If you don't want a partner page to exist, establish a direct redirect here.
  customHeader?: string;
  description?: string;
  pageVariant?: PageVariants;
};

// https://github.com/TheAtticusApp/atticus-shared-components/blob/master/src/constants/environments.ts
const consumerEnv = process.env.REACT_APP_CONSUMER_APP_ENV || "prod";
const consumerEnvs = enumToStringDictionary(ConsumerApp2);
export const ATTICUS_CONSUMER_APP_HOST =
  consumerEnvs[consumerEnv] || "https://app2.weareatticus.com";

// Hook into the Forms environment var to determine which environment we're in.
const formsEnv = process.env.REACT_APP_FORMS_ENV || "prod";
const formsEnvs = enumToStringDictionary(FormsApp);
console.log("formsEnv", formsEnvs[formsEnv]);
export const ATTICUS_FORMS_HOST =
  formsEnvs[formsEnv] || "https://forms.weareatticus.com";

// const marketingEnv = process.env.REACT_APP_MARKETING_ENV || "prod";
// const marketingEnvs = enumToStringDictionary(MarketingApp);
// export const ATTICUS_MARKETING_HOST =
//   marketingEnvs[marketingEnv] || "https://www.weareatticus.com";
export const ATTICUS_MARKETING = MarketingApp.prod;

export const isUIDev = process.env.REACT_APP_ENV === "dev";
export const isUIProd = process.env.REACT_APP_ENV === "prod";
export const IID_NAME = SHARED_CONSTANTS.COOKIES.inviteId;
export const INVITE_TTL = 30; // In Days
