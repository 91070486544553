import { FC } from "react";
import { Theme, Grid, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Section } from "components";
// import EmailInput from "./components/EmailInput";

const useStyles = makeStyles((theme: Theme) => ({
  emailSectionContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "50px 14px",
    },
  },
}));

const Template: FC = () => {
  const classes = useStyles();

  return (
    <Section
      containerProps={{
        style: {
          background: "linear-gradient(120.89deg, #734ED2 0%, #5E78E7 83.84%)",
          paddingTop: "64px",
          paddingBottom: "64px",
        },
        className: classes.emailSectionContainer,
      }}
    >
      <Typography
        style={{
          fontWeight: 800,
          fontSize: "24px",
          color: "#fff",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        Get the latest articles from our magazine every month
      </Typography>
      <Typography
        style={{
          color: "#fff",
          maxWidth: "600px",
          textAlign: "center",
          fontSize: "14px",
          margin: "20px 0 40px",
        }}
      >
        Atticus Magazine is a collection of thoughtful, informative, and
        confidence building stories that help guide you through managing your
        family’s estate.
      </Typography>
      <Grid container justifyContent="center">
        {/*<EmailInput buttonText="Subscribe" />*/}
        <div className="klaviyo-form-Tp5gKF" />
      </Grid>
    </Section>
  );
};

export default Template;
