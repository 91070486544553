interface Props {
  image: string;
  title: string;
  className?: string;
}

// Expect the logo to have 3 images
export const GenericLogo = ({ image, title, ...rest }: Props): JSX.Element => {
  return (
    <img
      srcSet={`${image}@1.5x.png 1.5x, ${image}@2x.png 2x, ${image}@3x.png 3x`}
      src={`${image}.png`}
      alt={title}
      title={title}
      {...rest}
    />
  );
};
