import {
  Partner,
  PageVariants,
  RedirectOptions,
  PartnerLogoFormats,
} from "./constants";

/**
 * Partner invite/sign-up flow:
 *  1. this app sets an AtticusInviteId cookie. The user is redirected to atticus-app sign-up when clicking "sign up"
 *    ../App.tsx
 *  2. atticus-app sign-up reads cookie and passes it to cognito upon sign up
 *    https://github.com/TheAtticusApp/atticus-app/blob/master/src/views/auth/HostedAuth.tsx
 *  3. post confirmation lambda trigger looks up inviteId in DynamoDB table and adds user to group
 *    https://github.com/TheAtticusApp/atticus-app/blob/master/amplify/backend/function/groupUser/ts/index.ts
 *
 * Invite records are created per group per environment when groups are created in the atticus-advisor admin tool
 *  They are stored in the advisor_groups.invite_id mysql field.  The ids in those fields must be set below.
 *  https://advisor.weareatticus.com/app/admin/groups
 *
 * inviteId refer to records in UserInvites DynamoDB table
 *  dev: https://us-east-2.console.aws.amazon.com/dynamodbv2/home?region=us-east-2#item-explorer?initialTagKey=&maximize=true&table=UserInvites-dev
 *  prod: https://us-east-2.console.aws.amazon.com/dynamodbv2/home?region=us-east-2#item-explorer?initialTagKey=&table=UserInvites-prod
 *
 */
export const PARTNERS: Partner[] = [
  {
    key: "atb",
    path: "/atb",
    title: "ATB Financial",
    showDisclaimerModal: true,
    description:
      "Atticus is proud to announce its partnership with ATB Financial, the largest Alberta based financial institution.",
    inviteId: {
      prod: "eev9lv086blkuimfsez",
      dev: "eev9lv086blkuimfsez",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.HORIZONTAL,
  },
  {
    key: "emprise",
    path: "/emprise-bank",
    title: "Emprise Bank",
    showDisclaimerModal: false,
    description:
      "Atticus is proud to announce its partnership with Emprise Bank.",
    inviteId: {
      prod: "neq8f2pztwkkuzqtn3n",
      dev: "neq8f2pztwkkuzqtn3n",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "aci",
    path: "/american-century-investments",
    title: "American Century Investments",
    showDisclaimerModal: false,
    customHeader:
      "American Century Investments is making Atticus available to you.",
    description:
      "Atticus helps its members settle their estates by providing a simple to-do list to help through the process. As an American Century Investments client referral, you are able to access the premium service for free, which gives you the added benefit of automated reporting and the ability to sync financial accounts.",
    inviteId: {
      prod: "f0k41w6op1kkuzqr2eq",
      dev: "f0k41w6op1kkuzqr2eq",
    },
    pageVariant: PageVariants.ACI,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "comerica",
    path: "/comerica",
    title: "Comerica",
    showDisclaimerModal: false,
    description: "Atticus is proud to announce its partnership with Comerica.",
    inviteId: {
      prod: "TBD",
      dev: "TBD",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "acmi",
    path: "/acmi",
    title: "Affinity Capital Management, Inc.",
    showDisclaimerModal: false,
    description:
      "Atticus is proud to announce its partnership with Affinity Capital Management, Inc.",
    inviteId: {
      prod: "72o1ig6o8wekwmkmlqx",
      dev: "9gc8usxqpj5l1oai4ip",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "stewardshipMatters",
    path: "/stewardship-matters",
    title: "Stewardship Matters Inc.",
    showDisclaimerModal: false,
    description:
      "Atticus is proud to announce its partnership with Stewardship Matters Inc.",
    inviteId: {
      prod: "6akn9qlgj13kydcmmp3",
      dev: "mkpnq286hqakydcdjsn",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "estateClosureCom",
    path: "/estateclosurecom",
    title: "EstateClosure.com",
    showDisclaimerModal: false,
    description:
      "Atticus is proud to announce its partnership with EstateClosure.com",
    inviteId: {
      prod: "6akn9qlgj13kydcmmp3",
      dev: "mkpnq286hqakydcdjsn",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "trusteeServicesGroup",
    path: "/trustee-services-group",
    title: "Trustee Services Group",
    showDisclaimerModal: false,
    description:
      "Atticus is proud to announce its partnership with Trustee Services Group",
    inviteId: {
      prod: "xp9eqg4eeylf08nilr",
      dev: "oeyvqnp8t9hlf08oaks",
    },
    pageVariant: PageVariants.ATTICUS_STATIC,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
  {
    key: "atticusFam",
    path: "/atticus-fam",
    title: "Atticus Fam",
    inviteId: {
      prod: "nkfi5bb7ibnlge1hvr1",
      dev: "rv081h4zbvmlge20n3s",
    },
    partnerRedirect: RedirectOptions.APP,
    logoFormat: PartnerLogoFormats.VERTICAL,
  },
];
