import { FC } from "react";
import { Card, CardContent, Grid, Typography, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Section, GenericLogo } from "components";
import useCommonStyles from "../useCommonStyles";
import { ATTICUS_MARKETING } from "../../../constants";

const cardLinksData = [
  {
    imageSrc: "/static/images/Atticus-Magazine",
    imageTitle: "Atticus Magazine",
    title: "Magazine",
    description: "Our guide to help you understand what’s next.",
    linkText: "Read the magazine",
    backgroundColor: "#EFFFF9",
    url: `${ATTICUS_MARKETING}/magazine`,
  },
  {
    imageSrc: "/static/images/Atticus-Resources",
    imageTitle: "Atticus Resources",
    title: "Resources",
    description: "Find the tools and resources you need to have peace of mind.",
    linkText: "Explore our resources",
    backgroundColor: "#EFEFFF",
    url: `${ATTICUS_MARKETING}/magazine/what-to-do-when-loved-one-dies`,
  },
  {
    imageSrc: "/static/images/Atticus-Heart",
    imageTitle: "Atticus Heart",
    title: "In lieu of flowers",
    description:
      "Help friends and family get through a hard time with this gift.",
    linkText: "Show your support",
    backgroundColor: "#F0F9FF",
    url: `${ATTICUS_MARKETING}/gift`,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "120px 16px",
  },
  innerContainer: {
    justifyContent: "space-between",
    alignItems: "stretch",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
    },
  },
  cardResource: {
    borderRadius: "8px",
    height: "100%",
    position: "relative",
  },
  cardImageWrapper: { "& img": { width: "100%" } },
}));

const Template: FC = () => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Section
      containerProps={{
        className: classes.container,
      }}
    >
      <Grid
        container
        rowSpacing={4}
        className={classes.innerContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* this should be a component... */}
        {cardLinksData.map((item) => (
          <Grid key={item.title} item xs={12} sm={6} md={4}>
            <Card
              style={{
                backgroundColor: item.backgroundColor,
              }}
              className={classes.cardResource}
              elevation={16}
            >
              <a href={item.url} style={{ height: "100%" }}>
                <CardContent
                  style={{
                    padding: "20px",
                    height: "100%",
                  }}
                >
                  <div className={classes.cardImageWrapper}>
                    <GenericLogo
                      image={item.imageSrc}
                      title={item.imageTitle}
                    />
                  </div>
                  <Typography
                    style={{
                      fontWeight: 800,
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    className={common.sectionDescription}
                    style={{ margin: "10px 0 55px" }}
                  >
                    {item.description}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                      padding: "16px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        marginRight: "12px",
                      }}
                    >
                      {item.linkText}
                    </Typography>
                    <img
                      src="/static/icons/Green-Arrow-Right.svg"
                      alt="Navigate"
                      title="Navigate"
                    />
                  </Grid>
                </CardContent>
              </a>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default Template;
