import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  ATTICUS_MARKETING,
  ATTICUS_FORMS_HOST,
  Partner,
  isUIDev,
  IID_NAME,
  INVITE_TTL,
} from "../constants";
import { isDev } from "@theatticusapp/atticus-shared-components";
import Cookies from "js-cookie";
import { PARTNERS } from "../partners";
import { getSignUpUrl } from "utils/getSignUpUrl";
interface Props {
  to?: string;
}
export const Redirect = ({ to }: Props): JSX.Element => {
  const { partnerPath } = useParams();

  const partner =
    PARTNERS.filter(
      (partner: Partner) => partner.path === "/" + partnerPath
    )[0] || undefined;

  if (to) {
    window.location.href = to;
  } else if (partner && partnerPath) {
    window.location.pathname = partnerPath;
  } else {
    window.location.href = ATTICUS_MARKETING;
  }

  return <></>;
};

const getPartnerFromPartnerPath = (
  partnerPath: string | undefined
): Partner | undefined => {
  const partner =
    PARTNERS.filter(
      (partner: Partner) => partner.path === "/" + partnerPath
    )[0] || undefined;

  return partner;
};

const setInviteIdCookie = (partner: Partner | undefined) => {
  const inviteId: string | undefined = isUIDev
    ? partner?.inviteId?.dev
    : partner?.inviteId?.prod;

  if (inviteId) {
    console.log("Setting cookie to", inviteId);
    Cookies.set(IID_NAME, inviteId, {
      expires: INVITE_TTL,
      domain: isDev ? window.document.location.hostname : ".weareatticus.com",
    });
  }
};

// Redirect to Forms App
export const PartnerFormsRedirect = (): JSX.Element => {
  const location = useLocation();
  const { partnerPath } = useParams();
  const partner = getPartnerFromPartnerPath(partnerPath);
  console.log("Partner", partner);
  setInviteIdCookie(partner);
  let newURL = ATTICUS_FORMS_HOST.toString();
  newURL =
    newURL +
    location.pathname
      .replace("/" + partnerPath, "")
      .replace("/forms-redirect", "");
  console.log("Redirecting to: " + newURL);
  window.location.href = newURL;
  return <></>;
};

// Redirect to Marketing App
export const PartnerMarketingRedirect = (): JSX.Element => {
  const location = useLocation();
  const { partnerPath } = useParams();
  const partner = getPartnerFromPartnerPath(partnerPath);
  console.log("Partner", partner);
  setInviteIdCookie(partner);
  let newURL = ATTICUS_MARKETING.toString();
  newURL =
    newURL +
    location.pathname
      .replace("/" + partnerPath, "")
      .replace("/marketing-redirect", "");
  console.log("Redirecting to: " + newURL);
  window.location.href = newURL;
  return <></>;
};

// Redirect to Consumer App
export const PartnerAppRedirect = (): JSX.Element => {
  const { partnerPath } = useParams();
  const partner = getPartnerFromPartnerPath(partnerPath);
  console.log("Partner", partner);
  setInviteIdCookie(partner);
  const newURL = getSignUpUrl();
  console.log("Redirecting to: " + newURL);
  window.location.href = newURL;
  return <></>;
};
