import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Logo } from "../../components/Logo";
import { ATTICUS_MARKETING } from "../../constants";

const linkGroups = [
  {
    title: "Products",
    links: [
      { url: `${ATTICUS_MARKETING}/for-families`, title: "For families" },
      { url: `${ATTICUS_MARKETING}/advisors`, title: "For advisors" },
      { url: `${ATTICUS_MARKETING}/gift`, title: "For loved ones" },
    ],
  },
  {
    title: "Learn",
    links: [
      { url: `${ATTICUS_MARKETING}/magazine`, title: "Magazine" },
      {
        url: `${ATTICUS_MARKETING}/magazine/what-to-do-when-loved-one-dies`,
        title: "Free checklist",
      },
    ],
  },
  {
    title: "Company",
    links: [
      { url: `${ATTICUS_MARKETING}/demo`, title: "Demo" },
      { url: `${ATTICUS_MARKETING}/faqs`, title: "About us" },
      { url: `${ATTICUS_MARKETING}/privacy-policy`, title: "Privacy Policy" },
      {
        url: `${ATTICUS_MARKETING}/terms-of-service`,
        title: "Terms of Service",
      },
    ],
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    fontSize: "14px",
    backgroundColor: "#fff",
    color: theme.palette.primary.dark,
  },
  innerContainer: {
    padding: "64px",
    width: "100%",
    maxWidth: "1100px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  outerLinkGroup: {
    color: "rgba(45, 42, 92, 0.85)",
    letterSpacing: "-0.164429px",
    gap: "40px 0",

    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
  linkGroup: {
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
  },
}));

const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container className={classes.innerContainer}>
        <Grid item xs={12} md={6}>
          <Logo trademark />
          <div style={{ margin: "23px 0 20px" }}>Join us at #weareatticus</div>
          <Grid
            container
            style={{ gap: "20px", marginBottom: "50px", alignItems: "center" }}
          >
            <a href="https://www.linkedin.com/company/weareatticus/">
              <img
                src="/static/icons/Linkedin.svg"
                alt="Linkedin"
                title="Linkedin"
              />
            </a>
            <a href="https://www.facebook.com/TheAtticusFam/">
              <img
                src="/static/icons/Facebook.svg"
                alt="Facebook"
                title="Facebook"
              />
            </a>
            <a href="https://twitter.com/theatticusapp">
              <img
                src="/static/icons/Twitter.svg"
                alt="Twitter"
                title="Twitter"
              />
            </a>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} container className={classes.outerLinkGroup}>
          {linkGroups.map((group) => (
            <Grid
              key={group.title}
              item
              xs={6}
              md={4}
              container
              direction="column"
              className={classes.linkGroup}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <span style={{ fontWeight: 600, fontSize: "16px" }}>
                  {group.title}
                </span>
                {group.links.map((item) => (
                  <a
                    key={item.title}
                    href={item.url}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <span style={{ fontWeight: 500, fontSize: "14px" }}>
                      {item.title}
                    </span>
                  </a>
                ))}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
