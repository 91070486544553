import React from "react";
import { PageVariants } from "../../../constants";

import AtticusStatic from "./AtticusStatic";
import AtticusDynamic from "./AtticusDynamic";
import ACIPage from "./ACIPage";
interface Props {
  variant: PageVariants;
}

export const PageVariant = ({ variant }: Props): JSX.Element => {
  if (variant == PageVariants.ATTICUS_DYNAMIC) {
    return <AtticusDynamic />;
  } else if (variant == PageVariants.ACI) {
    return <ACIPage />;
  } else {
    // By default, use the Atticus Static variant.
    return <AtticusStatic />;
  }
};

export default PageVariant;
