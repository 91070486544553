import React, { useContext } from "react";
import { Button, colors } from "@mui/material";
import { Card, CardContent, Theme, Grid, Typography, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Section } from "components";
import useCommonStyles from "../useCommonStyles";

import { AppContext } from "../../../App";

import { Logo } from "../../../components/Logo";
import aciLogo from "../../../images/american-century-investments-logo.svg";
import estateClosureLogo from "../../../images/EstateClosureCom-logo.png";
import atbLogo from "../../../images/atb-financial-logo.svg";
import empriseLogo from "../../../images/emprise-bank-logo.svg";
import acmiLogo from "../../../images/affinity-capital-atticus-app-partner.svg";
import comericaLogo from "../../../images/comerica-atticus-app-partner.svg";
import stewardshipMattersLogo from "../../../images/stewardship-matters-logo.png";
import tsgLogo from "../../../images/tsg-logo.svg";
import clsx from "clsx";
import { PartnerLogoFormats } from "./../../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "104px 16px 0 16px",
    [theme.breakpoints.up("md")]: {
      padding: "156px 16px 0 16px",
    },
    background:
      "url(/static/images/Purple-Gradient.png) no-repeat 0 60px local",
    backgroundSize: "100% calc(100% - 172px)",
  },
  content: {
    [theme.breakpoints.down("md")]: {
      gap: "48px",
    },
  },
  sponsorsContainer: {
    color: colors.common.white,
    background: "linear-gradient(120.89deg, #734ED2 0%, #5E78E7 83.84%)",
    borderRadius: "8px",
    fontSize: "14px",
    padding: "36px 0px 62px",
    minHeight: "184px",
    width: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "32px",

    marginTop: "84px",

    [theme.breakpoints.up("md")]: {
      marginTop: "84px",
    },
  },
  logoContainer: {
    width: "100%",
    maxWidth: "900px",
    display: "flex",
    flexWrap: "wrap",
    gap: "40px 0",
    margin: "0 -1rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    padding: "0 40px",
    "& img": {
      padding: "0 1rem",
      margin: "0 auto",
    },
  },
  partnershipCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      paddingTop: "0rem",
      paddingBottom: "0rem",
      gap: "0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: "0rem",
      paddingBottom: "0rem",
      gap: "0.5rem",
    },
  },
  partnershipCardGrid: {
    justifyContent: "end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  partnershipCardContainer: {
    height: "284px",
    width: "100%",
    maxWidth: "390px",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      height: "auto",
    },
  },
  partnerLogo: {
    maxHeight: "5rem",
    maxWidth: "100%",

    [theme.breakpoints.down("md")]: {
      height: "auto",
      maxWidth: "100%",
      minWidth: "0",
    },
  },
  atticusLogo: {
    maxWidth: 200,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxHeight: "none",
      width: "100%",
    },
  },
  partnershipElement: {
    display: "block",
  },
}));

// This is too ugly, why is there no asset folder in /src?
const forbesLogo = "/static/icons/Forbes-Logo.svg";
const seniorLivingLogo = "/static/icons/Senior-Living-Logo.svg";
const fcLogo = "/static/icons/Fast-Company-Logo.svg";
const logo11fs = "/static/icons/11FS-Logo.svg";

const partnerLogos: Record<string, string> = {
  atb: atbLogo,
  aci: aciLogo,
  emprise: empriseLogo,
  acmi: acmiLogo,
  comerica: comericaLogo,
  stewardshipMatters: stewardshipMattersLogo,
  estateClosureCom: estateClosureLogo,
  trusteeServicesGroup: tsgLogo,
};

const TeamingUp = (): JSX.Element => {
  const context = useContext(AppContext);
  const common = useCommonStyles();
  const classes = useStyles();

  let partnerLogo;
  if (context.partner?.key) {
    partnerLogo = partnerLogos[context.partner?.key];
  }

  return (
    <Section
      sectionProps={{ justifyContent: "space-between", direction: "row" }}
      containerProps={{ className: classes.container }}
    >
      <Grid
        item
        container
        style={{ flexWrap: "wrap-reverse" }}
        className={classes.content}
      >
        <Grid item container direction="column" xs={12} md={6}>
          <Grid item>
            <Box mb={4}>
              {context.partner?.customHeader && (
                <Typography variant="h1" className={common.title}>
                  {context.partner?.customHeader}
                </Typography>
              )}
              {!context.partner?.customHeader && (
                <Typography variant="h1" className={common.title}>
                  Atticus & {context.partner?.title} are teaming up
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box mb={6} style={{ maxWidth: "510px" }}>
              <Typography variant="body1">
                {context.partner?.description}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              className={clsx(common.fullWidthButton, "thinButton")}
              component="a"
              onClick={() => context.goToApp()}
            >
              Get started for free
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          className={classes.partnershipCardGrid}
        >
          <Card className={classes.partnershipCardContainer} elevation={8}>
            <CardContent className={classes.partnershipCard}>
              {context.partner?.logoFormat === PartnerLogoFormats.HORIZONTAL ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "36px",
                  }}
                >
                  {/* horizontal logo format */}
                  <Box
                    component="img"
                    src={partnerLogo}
                    className={classes.partnerLogo}
                    style={{
                      width: "120px",
                    }}
                  />
                  <div
                    style={{
                      width: "1px",
                      height: "95px",
                      backgroundColor: "#0072F0",
                    }}
                  ></div>
                  <Box
                    style={{
                      width: "120px",
                    }}
                  >
                    <Logo className={classes.atticusLogo} />
                  </Box>
                </Box>
              ) : (
                <>
                  {/* vertical logo format */}
                  <Box
                    component="img"
                    src={partnerLogo}
                    className={classes.partnerLogo}
                  />
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "34px",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    +
                  </Typography>
                  <Logo className={classes.atticusLogo} />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card className={classes.sponsorsContainer} elevation={16}>
        <Typography
          align="center"
          color="inherit"
          style={{ color: colors.common.white }}
        >
          Featured by
        </Typography>
        <div className={classes.logoContainer}>
          <img src={forbesLogo} alt="Forbes" title="Forbes" />
          <img
            src={seniorLivingLogo}
            alt="SeniorLiving.org Logo"
            title="SeniorLiving.org Logo"
          />
          <img src={fcLogo} alt="Fast Company" title="Fast Company" />
          <img src={logo11fs} alt="11FS" title="11FS" />
        </div>
      </Card>
    </Section>
  );
};

export default TeamingUp;
