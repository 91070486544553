export const ATBMarkdown = `
  # ATB Financial Acknowledgement, waiver and disclosure
  This disclosure has been prepared by ATB Financial (**“ATB”**) in connection with certain estate settlement information and related materials (collectively **“Information”**) provided by ATB.
  If, during the course of providing such Information, ATB has suggested that I engage the services of Atticus App, ULC, Atticus Inc., or any related company thereof (collectively **“Atticus”**) in the settlement of an estate, I acknowledge and agree as follows:
  - That ATB makes no representation or warranty regarding the website, products, or services maintained or provided by Atticus and that my use of the website, products and/or services is at my own risk in such state and condition as and when available from Atticus.
  - That ATB and Atticus are not affiliated or related companies and that ATB shall not be liable or responsible to me, or any other party, in any manner whatsoever, for the website, products, and/or services maintained or provided by Atticus, or for any other acts or omissions whatsoever of Atticus.
  - That any personal or other information that I may disclose to Atticus in connection with accessing the website, products, and/or services maintained or provided by Atticus shall be governed by the privacy policies and/or other such related policies of Atticus, along with such laws applicable thereto, and shall not be subject to ATB’s Privacy Code, Privacy Statement, or any other policies of ATB.
  - If I subscribe to Atticus services, Atticus may disclose my email address to ATB to allow ATB to gather feedback including improvement of the services.
  - Atticus may share anonymized, aggregated data with ATB to understand how the tool is used but will not share specific information about me or my estate.
  ## I further acknowledge and agree as follows:
  - Estate settlement involves unique and complex legal issues which may need to be considered in settling an estate for which it may be advisable for me to seek tax advice, accounting services, legal advice, or related services (collectively **“Professional Advice”**).
  - The Information provided by ATB is not intended to replace or serve as a substitute for Professional Advice. Neither ATB, nor any of its employees or representatives provide, nor intend to provide, Professional Advice..
  - ATB has advised me of the need (or potential need) for Professional Advice regarding the settling of an estate. I further acknowledge and agree that ATB has not provided me with any Professional Advice regarding the estate and that I have not relied on any Information provided by ATB in lieu of Professional Advice.
  - Information provided to me by ATB has been prepared in reliance on other information that I provided to ATB and ATB is not responsible and shall not be held liable for any errors, omissions or inaccuracies in any information that I provide to ATB. If any of my circumstances change at any time, I may wish to consult with ATB to confirm whether any changes to Information provided by ATB are appropriate or required.
  `;
