import { FC } from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  section: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "1100px",
  },
}));

interface Props {
  containerProps?: any;
  sectionProps?: any;
}

export const Section: FC<Props> = ({
  children,
  containerProps,
  sectionProps,
}) => {
  const classes = useStyles();

  return (
    <Grid
      {...containerProps}
      className={clsx(classes.sectionContainer, containerProps?.className)}
    >
      <Grid
        container
        direction="column"
        {...sectionProps}
        className={clsx(classes.section, sectionProps?.className)}
      >
        {children}
      </Grid>
    </Grid>
  );
};
