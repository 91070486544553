import { Grid, Card, CardContent, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { GenericLogo } from "../../../components";

const useStyles = makeStyles(() => ({
  incentiveCardContainer: {
    height: "362px",
    maxWidth: "100% !IMPORTANT",
  },
  incentiveCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    borderRadius: "8px",
    "& img": {
      height: "120px",
      width: "120px",
      marginTop: "40px",
    },
  },
  incentiveContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 24px",
    marginTop: "24px",
    marginBottom: "40px",
  },
}));

interface Props {
  iconSource: string;
  iconTitle: string;
  contentTitle: string;
  contentDescription: string;
}

const IncentivesCard = ({
  iconSource,
  iconTitle,
  contentTitle,
  contentDescription,
  ...rest
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      md={4}
      {...rest}
      className={classes.incentiveCardContainer}
    >
      <Card className={classes.incentiveCard} elevation={16}>
        <GenericLogo image={iconSource} title={iconTitle} />
        <CardContent className={classes.incentiveContent}>
          <Typography
            style={{
              fontWeight: 800,
              fontSize: 20,
              color: "#2D2A5C",
              marginBottom: 8,
            }}
          >
            {contentTitle}
          </Typography>
          <Typography
            align="center"
            style={{ fontWeight: 500, fontSize: 16, color: "#595A5F" }}
          >
            {contentDescription}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default IncentivesCard;
