import { FC } from "react";
import { Typography, Box, Container, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import ReactPlayer from "react-player/youtube";

import { Section } from "components";
import useCommonStyles from "../useCommonStyles";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "132px !important",
    [theme.breakpoints.down("md")]: {
      marginTop: "84px !important",
    },
  },
  carouselIndicator: {
    margin: "0 10px",
    color: "#ABB1DB",
  },
  activeCarouselIndicator: {
    color: "#4E57AA",
  },
  carouselContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "64px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "100px",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  videoContainer: {
    height: "324px",
    width: "573px",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
      width: "100%",
    },
  },
  carousel: {
    height: "100%",
    width: "100%",
  },
}));

const Section3: FC = () => {
  const common = useCommonStyles();
  const classes = useStyles();

  return (
    <Section
      containerProps={{
        style: {
          background:
            "url(/static/images/Purple-Gradient-Styled.png) no-repeat top center local",
          backgroundSize: "cover",
          paddingLeft: 0,
          paddingRight: 0,
          alignItems: "center",
          marginTop: "40px",
          paddingBottom: "48px",
        },
        className: classes.container,
      }}
    >
      <Carousel
        activeIndicatorIconButtonProps={{
          className: classes.activeCarouselIndicator,
        }}
        indicatorIconButtonProps={{
          className: classes.carouselIndicator,
        }}
        autoPlay={false}
        className={classes.carousel}
        navButtonsAlwaysInvisible
      >
        <Container className={classes.carouselContainer}>
          <Typography
            className={common.title}
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: 34,
              color: "#2D2A5C",
            }}
          >
            Take Atticus for a spin
          </Typography>
          <Box margin="32px 0" className={classes.videoContainer}>
            <ReactPlayer
              className="YouTube video player"
              url="https://www.youtube.com/embed/fJL_VwQ4kKQ?controls=0"
              width="100%"
              height="100%"
            />
          </Box>
        </Container>
        {/*<Container className={classes.carouselContainer}>*/}
        {/*  <Typography*/}
        {/*    className={common.title}*/}
        {/*    style={{*/}
        {/*      textAlign: "center",*/}
        {/*      fontWeight: 800,*/}
        {/*      fontSize: 34,*/}
        {/*      color: "#2D2A5C",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Take atticus for a spin*/}
        {/*  </Typography>*/}
        {/*  <Box margin="32px 0" className={classes.videoContainer}>*/}
        {/*    <ReactPlayer*/}
        {/*      className="YouTube video player"*/}
        {/*      url="https://www.youtube.com/embed/W5s-XYxB9uE?controls=0"*/}
        {/*      width="100%"*/}
        {/*      height="100%"*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Container>*/}
      </Carousel>
    </Section>
  );
};

export default Section3;
