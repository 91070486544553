import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
      },
      a: {
        color: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.primary.dark,
        },
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ul: {
        padding: "10px 20px",
      },
    },
  })
);

const GlobalStyles = (): null => {
  useStyles();
  return null;
};

export default GlobalStyles;
