import { FC, useState, useRef, useContext } from "react";
import clsx from "clsx";
import {
  Theme,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Stack,
  Collapse,
  Fade,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Logo } from "../../components/Logo";
import { ATTICUS_MARKETING } from "../../constants";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useOnClickOutside } from "../../hooks/onClicks";
import { AppContext } from "../../App";

const forFamiliesLink = `${ATTICUS_MARKETING}/for-families`;
// const forEmployersLink = `${ATTICUS_MARKETING}/for-employers`;
const forAdvisorsLink = `${ATTICUS_MARKETING}/advisors`;
const learnLink = `${ATTICUS_MARKETING}/magazine`;
const giftLink = `${ATTICUS_MARKETING}/gift`;

const useStyles = makeStyles((theme: Theme) => ({
  mobileMenuContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5);",
    position: "fixed",
    left: 0,
    right: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  mobileMenu: {
    width: "100%",
    backgroundColor: "white",
    left: 0,
    right: 0,
  },
  link: {
    color: "#2D2A5C",
    weight: 600,
    size: 16,
    "&:hover": {
      color: "#01D9C6",
    },
  },
  mobileHide: {
    [theme.breakpoints.down("lg")]: {
      display: "none !important",
    },
  },
  button: {
    height: "32px",
  },
  logo: {
    height: "27px",
  },
}));

const TopBar: FC = () => {
  const context = useContext(AppContext);
  const classes = useStyles();
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openMenu = () => {
    setMenuOpen(true);
  };

  useOnClickOutside(ref, closeMenu);

  return (
    <AppBar
      color="inherit"
      elevation={0}
      sx={{
        paddingLeft: {
          xl: 35,
          lg: 20,
          md: 2,
          xs: 2,
        },
        paddingRight: {
          xl: 35,
          lg: 20,
          md: 2,
          xs: 2,
        },
      }}
    >
      <Fade in={menuOpen} timeout={300}>
        <Box className={classes.mobileMenuContainer}>
          <Collapse in={menuOpen} timeout={300}>
            <Box className={classes.mobileMenu}>
              <Stack
                spacing={5}
                sx={{
                  paddingTop: 10,
                  paddingLeft: 5,
                  paddingBottom: 6,
                }}
              >
                <a href={giftLink}>
                  <Typography className={classes.link} component="div">
                    Give the gift
                  </Typography>
                </a>
                <a href={forFamiliesLink}>
                  <Typography className={classes.link} component="div">
                    For families
                  </Typography>
                </a>
                <a href={forAdvisorsLink}>
                  <Typography className={classes.link} component="div">
                    For advisors
                  </Typography>
                </a>
                {/*<a href={forEmployersLink}>*/}
                {/*  <Typography className={classes.link} component="div">*/}
                {/*    For employers*/}
                {/*  </Typography>*/}
                {/*</a>*/}
                <a href={learnLink}>
                  <Typography className={classes.link} component="div">
                    Learn
                  </Typography>
                </a>
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Fade>
      <Toolbar>
        <Stack direction="row" spacing={10} sx={{ alignItems: "center" }}>
          <a href={ATTICUS_MARKETING}>
            <Logo className={classes.logo} />
          </a>
          <Stack direction="row" spacing={3} className={classes.mobileHide}>
            <a href={forFamiliesLink}>
              <Typography className={classes.link} component="div">
                For families
              </Typography>
            </a>
            <a href={forAdvisorsLink}>
              <Typography className={classes.link} component="div">
                For advisors
              </Typography>
            </a>
            {/*<a href={forEmployersLink}>*/}
            {/*  <Typography className={classes.link} component="div">*/}
            {/*    For employers*/}
            {/*  </Typography>*/}
            {/*</a>*/}
            <a href={learnLink}>
              <Typography className={classes.link} component="div">
                Learn
              </Typography>
            </a>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
            className={clsx(classes.mobileHide, classes.button)}
            component="a"
            href={giftLink}
          >
            Give the gift
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            component="a"
            onClick={() => context.goToApp()}
          >
            Sign up
          </Button>
          <IconButton
            ref={ref}
            size="large"
            edge="start"
            color="inherit"
            aria-label={menuOpen ? "close" : "menu"}
            sx={{
              display: {
                lg: "none",
              },
            }}
            onClick={menuOpen ? closeMenu : openMenu}
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
