// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getOverrides = (palette, theme) => ({
  MuiButtonBase: {
    styleOverrides: {
      root: {
        borderRadius: "333px !important",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        borderRadius: "333px",
      },
      contained: {
        boxShadow: "none",
        "&.phatButton": {
          padding: theme.spacing(2, 6),
        },
      },
      outlined: {
        border: `2px solid ${palette.secondary.main} !important`,
        color: `${palette.secondary.main} !important`,
        padding: "6px 16px !important",
      },
      label: {
        fontWeight: 600,
      },
      containedSecondary: {
        backgroundColor: `${palette.secondary.main} !important`,
        color: `${palette.common.white} !important`,
        "&:hover": {
          color: palette.common.white,
        },
        "&.thinButton": {
          padding: "6px 16px",
          // fontStyle: "italic",
        },
      },
      sizeLarge: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        letterSpacing: "-0.16px",
      },
      h1: {
        color: palette.primary.dark,
        fontWeight: 800,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation8: {
        boxShadow:
          "0px 5px 5px -3px rgba(116, 135, 164, 0.2), 0px 8px 10px 1px rgba(116, 135, 164, 0.14), 0px 3px 14px 2px rgba(116, 135, 164, 0.12)",
      },
      elevation16: {
        boxShadow:
          "0px 8px 10px -5px rgba(116, 135, 164, 0.2), 0px 16px 24px 2px rgba(116, 135, 164, 0.14), 0px 6px 30px 5px rgba(116, 135, 164, 0.12);",
      },
      rounded: {
        borderRadius: "8px",
      },
    },
  },
});
