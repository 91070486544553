import { FC, useContext } from "react";
import { Theme, Grid, Typography, Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { AppContext } from "../../../App";
import { Section } from "components";
import useCommonStyles from "../useCommonStyles";
import TestimonialImage from "./TestimonialImage";

const valuePropsItems = [
  {
    iconSrc: "/static/icons/Clock-icon.svg",
    iconTitle: "Clock",
    title: "Save time and money, focus on family",
    description:
      "Save hundreds of hours searching for answers and thousands of dollars in unnecessary fees.",
  },
  {
    iconSrc: "/static/icons/Path-icon.svg",
    iconTitle: "Path",
    title: "Dedicated support, from start to finish",
    description:
      "Our team of experts are prepared to help, down to the specifics of your state and county.",
  },
  {
    iconSrc: "/static/icons/Shield-icon.svg",
    iconTitle: "Shield",
    title: "Liability protection, guaranteed*",
    description:
      "Protect your family and estate from surprise debt collection when you least expect it.",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "126px",
    paddingBottom: "126px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "84px",
      paddingBottom: "84px",
    },
  },
  testimonialSection: {
    marginTop: "50px",
    flexWrap: "wrap-reverse",
    gap: "20px 0",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      padding: 0,
    },
  },
  testimonialImage: {
    [theme.breakpoints.down("md")]: {
      // transform: "translate(-25%, 0)",
      overflow: "hidden",
    },
  },
  testimonialInnerSection: {
    [theme.breakpoints.down("md")]: {
      padding: "0px 14px",
    },
  },

  valuePropsButtonContainer: {
    marginLeft: "90px",
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
      marginLeft: 0,
      width: "311px",
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mobileTitle: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      margin: "0 auto 44px auto !IMPORTANT",
      width: "80%",
    },
  },
  bulletsContainer: { margin: "0 auto", width: "fit-content" },
}));

interface Props {
  customTitle?: string;
}

const Section4: FC<Props> = ({ customTitle }) => {
  const context = useContext(AppContext);
  const common = useCommonStyles();
  const classes = useStyles();

  return (
    <Section
      containerProps={{
        style: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        className: classes.container,
      }}
    >
      {customTitle && (
        <Typography
          className={clsx(common.title, common.mobileHide)}
          align="center"
          style={{
            maxWidth: "750px",
            fontWeight: 800,
            fontSize: 34,
            color: "#2D2A5C",
          }}
        >
          {customTitle}
        </Typography>
      )}
      {!customTitle && (
        <Typography
          className={clsx(common.title, common.mobileHide)}
          align="center"
          style={{
            maxWidth: "750px",
            fontWeight: 800,
            fontSize: 34,
            color: "#2D2A5C",
          }}
        >
          Atticus helps {context.partner?.title} members settle their estates.
        </Typography>
      )}
      <Grid container className={classes.testimonialSection}>
        <Grid item xs={12} md={6}>
          <TestimonialImage className={classes.testimonialImage} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          className={classes.testimonialInnerSection}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              className={(common.title, classes.mobileTitle)}
              style={{
                fontWeight: 800,
                fontSize: 34,
                color: "#2D2A5C",
                marginBottom: "44px",
              }}
            >
              Personalized, step-by-step guidance for executors
            </Typography>
            <Box className={classes.bulletsContainer}>
              {valuePropsItems.map((item: any) => (
                <Grid key={item.title} item container>
                  <Grid item xs style={{ maxWidth: "90px" }}>
                    <img
                      src={item.iconSrc}
                      alt={item.iconTitle}
                      title={item.iconTitle}
                    />
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography
                      style={{
                        margin: "8px 0",
                        fontSize: "20px",
                        color: "#2D2A5C",
                        fontWeight: 800,
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      className={common.sectionDescription}
                      style={{ maxWidth: "390px" }}
                    >
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box mt="40px" className={classes.valuePropsButtonContainer}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className={clsx(common.fullWidthButton, "thinButton")}
                component="a"
                onClick={() => context.goToMarketing()}
              >
                See how it works
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Section4;
