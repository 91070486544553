import React, { useState, createContext, useContext, useEffect } from "react";
import { Dialog, ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import GlobalStyles from "./components/GlobalStyles";
import RemoveTrailingSlash from "./components/RemoveTrailingSlash";
import { themes } from "./theme";
import { DisclaimerContent } from "./views/HomePage/DisclaimerComponents/DisclaimerContent";
import Cookies from "js-cookie";
import {
  Partner,
  isUIDev,
  IID_NAME,
  INVITE_TTL,
  ATTICUS_MARKETING,
  ATTICUS_MARKETING_LEARN_MORE,
} from "./constants";
import { getPartner } from "./utils/getPartner";
import { isDev } from "@theatticusapp/atticus-shared-components";
import { getSignUpUrl } from "./utils/getSignUpUrl";
import { hotjar } from "react-hotjar";

interface AppContextInterface {
  goToApp: (email?: string) => void;
  goToMarketing: () => void;
  partner?: Partner;
}

const defaultState = {
  goToApp: () => null,
  goToMarketing: () => null,
  partner: undefined,
};

const AppContext = createContext<AppContextInterface>(defaultState);

export const App = (): JSX.Element => {
  const routing = useRoutes(routes);
  const context = useContext(AppContext);
  let inviteId: string | undefined;

  context.partner = getPartner();

  (function setInviteIdCookie() {
    inviteId = isUIDev
      ? context.partner?.inviteId?.dev
      : context.partner?.inviteId?.prod;

    if (inviteId) {
      Cookies.set(IID_NAME, inviteId, {
        expires: INVITE_TTL,
        domain: isDev ? window.document.location.hostname : ".weareatticus.com",
      });
    }
  })();

  // Configure Hotjar
  useEffect(() => {
    hotjar.initialize(3272323, 6);
  }, []);

  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [email, setEmail] = useState("");
  const handleOpen = () => setDisclaimerOpen(true);
  const handleClose = () => setDisclaimerOpen(false);

  const handleGoToApp = (email?: string) => {
    if (context.partner?.showDisclaimerModal) {
      if (email) setEmail(email);
      handleOpen();
    } else {
      window.location.href = getSignUpUrl(email);
    }
  };

  const handleGoToMarketing = () => {
    window.location.href = ATTICUS_MARKETING + ATTICUS_MARKETING_LEARN_MORE;
  };

  return (
    <AppContext.Provider
      value={{
        goToApp: (email?: string) => handleGoToApp(email),
        goToMarketing: () => handleGoToMarketing(),
        partner: context.partner,
      }}
    >
      <ThemeProvider theme={themes.light}>
        <GlobalStyles />
        <Dialog
          open={disclaimerOpen}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          scroll="paper"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DisclaimerContent email={email} />
        </Dialog>
        <RemoveTrailingSlash />
        {routing}
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export { AppContext };
