import React, { ForwardedRef, forwardRef, ReactElement } from "react";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

interface Props {
  className?: string;
  children: ReactElement;
  title?: string;
  rest?: any[];
}

const Page = forwardRef(
  (
    { children, title = "", className = "", ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Container
        disableGutters
        maxWidth={false}
        className={className}
        ref={ref}
        {...rest}
        style={{ backgroundColor: "#fff" }}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </Container>
    );
  }
);

export default Page;
