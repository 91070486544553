import { Partner } from "../constants";
import { PARTNERS } from "../partners";

export const getPartner = function (): Partner | undefined {
  return (
    PARTNERS.filter(
      (partner: Partner) => partner.path === location.pathname
    )[0] || undefined
  );
};
