import { FC } from "react";
import { Grid, Typography, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Section } from "components";
import useCommonStyles from "../useCommonStyles";
import IncentivesCard from "./IncentivesCard";

const icons = {
  alarmClockIcon: "/static/icons/Alarm-clock-icon",
  hourglassIcon: "/static/icons/Hourglass-icon",
  moneyIcon: "/static/icons/Money-icon",
};

const useStyles = makeStyles(() => ({
  sectionPadding: {
    paddingTop: "84px",
    paddingRight: "16px",
    paddingLeft: "16px",
  },
  cardGroup: {
    justifyContent: "center",
    alignItems: "center",
    gap: "20px 0",
  },
}));

const AtticusBenefit: FC = () => {
  const common = useCommonStyles();
  const classes = useStyles();

  return (
    <Section
      containerProps={{
        className: classes.sectionPadding,
      }}
    >
      <Box mb="48px">
        <Typography
          variant="h1"
          className={common.title}
          align="center"
          style={{ maxWidth: "830px" }}
        >
          Without Atticus, estate settlement and probate are confusing and
          overwhelming
        </Typography>
      </Box>

      <Grid
        container
        className={classes.cardGroup}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <IncentivesCard
            iconSource={icons.alarmClockIcon}
            iconTitle="Alarm Clock"
            contentTitle="~ 12 hours per week"
            contentDescription="The amount of time executors spend per week dealing with the
      legal and social responsibilities following the loss of a
      loved on."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IncentivesCard
            iconSource={icons.hourglassIcon}
            iconTitle="Hourglass"
            contentTitle="~ 1 year of work"
            contentDescription="On top of dealing with grief and massive change, they’ll have
      to navigate the legal complexities of probate and estate
      settlement."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IncentivesCard
            iconSource={icons.moneyIcon}
            iconTitle="Money"
            contentTitle="~ $14k in cost"
            contentDescription="These costs take families by surprise. Over 50% of Americans
      don’t even have $400 saved up for unplanned expenses like
      this."
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default AtticusBenefit;
