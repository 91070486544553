import React from "react";
import { isDev } from "@theatticusapp/atticus-shared-components";
import { Outlet } from "react-router-dom";
import { Typography, Container } from "@mui/material";
import TopBar from "./TopBar";
import { ATTICUS_MARKETING } from "../../constants";
import { Redirect } from "../../components/Redirect";

export const MainLayout = (): JSX.Element => {
  const isRoot = location.pathname === "/";

  return (
    <Container disableGutters maxWidth={false}>
      <TopBar />
      {isRoot ? (
        !isDev ? (
          <Redirect to={ATTICUS_MARKETING} />
        ) : (
          <Typography sx={{ my: 20, textAlign: "center" }} variant="h4">
            Expected a partner key in the path. Example: <a href="/atb">/atb</a>
            .<br />
            Other environments will redirect to {ATTICUS_MARKETING}
          </Typography>
        )
      ) : (
        <Outlet />
      )}
    </Container>
  );
};
