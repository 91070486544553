import React, { FC, useContext } from "react";
import { Theme, Grid, Typography, Box, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Section, GenericLogo } from "components";
import useCommonStyles from "../useCommonStyles";
import clsx from "clsx";
import { AppContext } from "../../../App";

const atticusAppAssets = "/static/images/Atticus-App-Assets";
const atticusAppReports = "/static/images/Atticus-App-Reports";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  appFeaturesContainer: {
    marginTop: "130px",
    gap: "32px 0",

    [theme.breakpoints.down("md")]: {
      marginTop: "80px",
    },
  },
  buttonContainer: {
    maxWidth: "311px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  image: { width: "100%", maxWidth: "445px" },
}));

const Template: FC = () => {
  const context = useContext(AppContext);
  const common = useCommonStyles();
  const classes = useStyles();

  return (
    <Section>
      <Grid
        container
        alignItems="center"
        className={classes.container}
        style={{ flexWrap: "wrap-reverse", gap: "32px 0" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          style={{ padding: "0 16px", alignItems: "center" }}
        >
          <Box sx={{ maxWidth: "430px", width: "100%" }}>
            <Typography
              style={{ fontWeight: 800, fontSize: 28, color: "#2D2A5C" }}
            >
              Capture what their loved ones left behind
            </Typography>
            <Box mt="24px" mb="40px" maxWidth="385px">
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Atticus makes it simple for the entire family to digitally
                capture and divide the estate fairly, based on any wills.
              </Typography>
            </Box>
            <div className={classes.buttonContainer}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className={clsx(common.fullWidthButton, "thinButton")}
                component="a"
                onClick={() => context.goToApp()}
              >
                Get started for free
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container direction="column">
          <div className={classes.imageContainer}>
            <GenericLogo
              image={atticusAppAssets}
              title="Atticus App Assets"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        className={classes.appFeaturesContainer}
      >
        <Grid item xs={12} md={6} container direction="column">
          <div className={classes.imageContainer}>
            <GenericLogo
              image={atticusAppReports}
              title="Atticus App Reports"
              className={classes.image}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          style={{ padding: "0 16px", alignItems: "center" }}
        >
          <Box maxWidth="430px">
            <Typography
              style={{ fontWeight: 800, fontSize: 28, color: "#2D2A5C" }}
            >
              On-demand estate reports and local forms
            </Typography>
            <Box mt="24px" mb="40px" maxWidth="385px">
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                We’ll make sure they have the forms and reports they need in
                order to fulfill their legal obligation as executor.
              </Typography>
            </Box>
            <div className={classes.buttonContainer}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className={clsx(common.fullWidthButton, "thinButton")}
                component="a"
                onClick={() => context.goToApp()}
              >
                Get started for free
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Template;
