import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);

const startupLog =
  "environment: " +
  process.env.NODE_ENV +
  "; consumer app env: " +
  process.env.REACT_APP_CONSUMER_APP_ENV +
  "; version: " +
  process.env.REACT_APP_VERSION;

console.log(startupLog);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
