import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Theme } from "@mui/material";
import { GenericLogo } from "components";

const atticusApp = "/static/images/Testimonials/Atticus-App";
const atticusAppTestimonial =
  "/static/images/Testimonials/Atticus-App-Testimonial";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
    },
  },
  atticusPhoneImage: {
    position: "relative",
    left: "-70px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      left: "0",
      width: "inherit",
    },
    [theme.breakpoints.down("sm")]: {
      left: "-10%",
      // width: "275px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "-20%",
    },
    "& img": {
      // maxWidth: "445px",
      width: "445px",
    },
  },
  testimonial1: {
    position: "absolute",
    top: "138px",
    right: "0px",
    [theme.breakpoints.down("md")]: {
      top: "150px",
      right: "-15%",
    },
    [theme.breakpoints.down("sm")]: {
      right: "-5%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "5%",
    },
    "& img": {
      width: "275px",
    },
  },
  testimonial2: {
    position: "absolute",
    top: "395px",
    left: "110px",

    [theme.breakpoints.down("md")]: {
      top: "360px",
      left: "0px",
    },
  },
}));

interface Props {
  className?: string;
}

const TestimonialImage = ({ className }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.atticusPhoneImage}>
        <GenericLogo image={atticusApp} title="Atticus App" />
        <div className={classes.testimonial1}>
          <GenericLogo
            image={atticusAppTestimonial}
            title="Atticus App Testimonial"
          />
        </div>
        {/*<div className={classes.testimonial2}>*/}
        {/*  <img*/}
        {/*    src={atticusAppUser}*/}
        {/*    alt="Atticus App User"*/}
        {/*    title="Atticus App User"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default TestimonialImage;
