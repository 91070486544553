import { FC } from "react";
import { Card, CardContent, Theme, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { Section } from "components";
import useCommonStyles from "../useCommonStyles";

const section5CardContent = [
  {
    accentColor: "#9292FF",
    iconSrc: "/static/icons/Thumbs-Up-Ribbon-Icon.svg",
    iconTitle: "Thumbs Up Ribbon",
    title: "Complete confidence",
  },
  {
    accentColor: "#01D9C6",
    iconSrc: "/static/icons/Bank-Ribbon-Icon.svg",
    iconTitle: "Bank Ribbon",
    title: "Protection from liability",
  },
  {
    accentColor: "#6590FF",
    iconSrc: "/static/icons/People-Group-Ribbon-Icon.svg",
    iconTitle: "People Group Ribbon",
    title: "Transparency for the entire family",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  seniorWomanSmilingContainer: {
    position: "absolute",
    width: "100vw",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 0,

    [theme.breakpoints.down("md")]: {
      position: "relative",
      paddingTop: "340px",
      width: "100%",
    },
  },
  seniorWomanSmiling: {
    position: "relative",
    bottom: "-45px",

    [theme.breakpoints.down("md")]: {
      position: "absolute",
      bottom: "-100px",
      right: "-16px",
    },
  },
  customBackgroundPadding: {
    background:
      "url(/static/images/Purple-Gradient.png) no-repeat top center local",
    backgroundSize: "100% calc(100% - 112px)",
    padding: "80px 16px",

    [theme.breakpoints.down("md")]: {
      background:
        "url(/static/images/Purple-Gradient.png) no-repeat top center local",
      backgroundSize: "100% 75%",
      padding: "40px 16px",
    },
  },
  mobileIcon: {
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
  },
  cardIcon: {
    marginTop: "5px",
  },
  cardContainer: {
    zIndex: 1,
    marginTop: "100px",
    gap: "20px 0",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      marginTop: "50px",
    },
  },
}));

const atticusHeart = "/static/icons/Atticus-heart.svg";
const seniorWomanSmiling = "/static/images/Senior-Woman-Smiling.png";

const Section5: FC = () => {
  const common = useCommonStyles();
  const classes = useStyles();

  return (
    <Section
      containerProps={{
        className: classes.customBackgroundPadding,
      }}
    >
      <img
        src={atticusHeart}
        alt="Atticus Heart"
        title="Atticus Heart"
        className={classes.mobileIcon}
      />
      <Typography
        className={clsx(common.subtitle, common.mobileHide)}
        style={{
          margin: "24px 16px 16px",
          zIndex: 1,
          fontWeight: 800,
          fontSize: 28,
          color: "#2D2A5C",
        }}
      >
        The Atticus Promise
      </Typography>
      <Typography
        align="center"
        style={{
          maxWidth: "500px",
          zIndex: 1,
          padding: "0 16px",
          textAlign: "center",
          color: "#2D2A5C",
        }}
      >
        Atticus supports the wellbeing of executors and their families
        throughout the entire estate settlement, probate, and inheritance
        journey—from account creation to close.
      </Typography>
      <div className={classes.seniorWomanSmilingContainer}>
        <img
          src={seniorWomanSmiling}
          alt="Senior Woman Smiling"
          title="Senior Woman Smiling"
          className={classes.seniorWomanSmiling}
        />
      </div>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className={classes.cardContainer}
      >
        {section5CardContent.map((item) => (
          <Grid
            key={item.title}
            item
            xs={12}
            sm={6}
            md={4}
            style={{
              maxHeight: "130px",
            }}
          >
            <Card
              style={{
                borderRadius: "6.44px",
                boxShadow: "0px 12.878px 19.3171px rgba(116, 135, 164, 0.16)",
                height: "100px",
              }}
            >
              <CardContent style={{ padding: "0" }}>
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    height: "6px",
                    backgroundColor: item.accentColor,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "18px",
                    height: "94px",
                    padding: "0 16px 0 26px",
                  }}
                >
                  <img
                    className={classes.cardIcon}
                    src={item.iconSrc}
                    alt={item.iconTitle}
                    title={item.iconTitle}
                  />
                  <Typography style={{ fontSize: "12px", fontWeight: 800 }}>
                    {item.title}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default Section5;
