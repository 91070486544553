import React from "react";
import { isDev } from "@theatticusapp/atticus-shared-components";
import Page from "../../components/Page";

import {
  ATTICUS_MARKETING,
  ATTICUS_PAGE_PREFIX,
  PageVariants,
  Partner,
  RedirectOptions,
} from "../../constants";
import { PARTNERS } from "../../partners";

import { useParams } from "react-router-dom";
import {
  Redirect,
  PartnerMarketingRedirect,
  PartnerAppRedirect,
  PartnerFormsRedirect,
} from "../../components/Redirect";
import { Typography } from "@mui/material";
import { PageVariant } from "./PageVariants";

export const HomePage = (): JSX.Element => {
  const { partnerPath } = useParams();
  const partner =
    PARTNERS.filter(
      (partner: Partner) => partner.path === "/" + partnerPath
    )[0] || undefined;

  if (partner && partner.partnerRedirect) {
    if (
      partner.partnerRedirect.valueOf() == RedirectOptions.MARKETING.valueOf()
    ) {
      return <PartnerMarketingRedirect />;
    } else if (
      partner.partnerRedirect.valueOf() == RedirectOptions.APP.valueOf()
    ) {
      return <PartnerAppRedirect />;
    } else if (
      partner.partnerRedirect.valueOf() == RedirectOptions.FORMS.valueOf()
    ) {
      return <PartnerFormsRedirect />;
    }
  }

  return partner && !partner.partnerRedirect ? (
    <Page title={`${ATTICUS_PAGE_PREFIX} - ${partner?.title}`}>
      <PageVariant
        variant={partner.pageVariant || PageVariants.ATTICUS_STATIC}
      />
    </Page>
  ) : isDev ? (
    <Typography sx={{ my: 20, textAlign: "center" }} variant="h4">
      Partner "{partnerPath}" undefined
      <br />
      Other environments will redirect to {ATTICUS_MARKETING}
    </Typography>
  ) : (
    <Redirect to={ATTICUS_MARKETING} />
  );
};
