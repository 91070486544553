import React from "react";
import {
  Redirect,
  PartnerMarketingRedirect,
  PartnerAppRedirect,
  PartnerFormsRedirect,
} from "./components/Redirect";

import { MainLayout } from "./layouts/Main";

import { HomePage } from "./views/HomePage";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [{ path: ":partnerPath", element: <HomePage /> }],
  },
  {
    path: "/:partnerPath/marketing-redirect/*",
    element: <PartnerMarketingRedirect />,
  },
  {
    path: "/:partnerPath/app-redirect/*",
    element: <PartnerAppRedirect />,
  },
  {
    path: "/:partnerPath/forms-redirect/*",
    element: <PartnerFormsRedirect />,
  },
  {
    path: "/:partnerPath/*",
    element: <Redirect />,
  },
];

export default routes;
