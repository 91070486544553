import React, { useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import makeStyles from "@mui/styles/makeStyles";

// BEGIN Disclaimer Markdown Imports
import { ATBMarkdown } from "./DisclaimerMarkdown";
interface DisclaimerMappings {
  [key: string]: string;
}
const disclaimerMappings: DisclaimerMappings = {
  atb: ATBMarkdown,
};
// END Disclaimer Markdown Imports

import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import AtticusA from "../../../images/Atticus-A.svg";
import atticusColors from "../../../theme/colors";
import { AppContext } from "../../../App";
import { getSignUpUrl } from "../../../utils/getSignUpUrl";

const bulletSX = {
  height: 8,
  width: 8,
  borderRadius: "50%",
  backgroundColor: "secondary.main",
  margin: 1,
  marginRight: 2,
  marginLeft: 0,
  display: "inline block",
};
interface Props {
  email: string;
}

// Styles for Disclaimer Scrollbox Area
const useStyles = makeStyles({
  reactMarkDown: {
    "& p": {
      marginBottom: "10px",
    },
    "& li": {
      marginBottom: "10px",
    },
    "& h1": {
      textAlign: "center",
      fontWeight: "700",
      fontSize: "18px",
      textDecoration: "underline",
      marginBottom: "20px",
    },
    "& h2": {
      textAlign: "center",
      fontWeight: "700",
      fontSize: "16px",
      marginBottom: "10px",
    },
  },
});

export const DisclaimerContent = ({ email }: Props): JSX.Element => {
  const context = useContext(AppContext);
  const [checked, setChecked] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const mdStyles = useStyles();

  let markdownString = ``;
  if (context.partner) {
    markdownString = disclaimerMappings[context.partner?.key];
  }

  const bulletContent = [
    {
      text: `Your existing ${context.partner?.title} account(s) will be different than your new Atticus account.`,
    },
    {
      text: `To keep your information safe, we recommend using a strong password that is different than your ${context.partner?.title} account’s password.`,
    },
    {
      text: `Atticus and ${context.partner?.title} do not provide professional advice, but can help connect you with experts who can provide accounting services, legal advice, or related professional services.`,
    },
  ];

  const checkBoxClicked = (e: any) => {
    e.preventDefault();
    setChecked(e.target.checked);
    setShowErrorText(false);
  };

  const continueClicked = (e: any) => {
    e.preventDefault();
    if (checked) {
      window.location.href = getSignUpUrl(email);
    } else {
      setShowErrorText(true);
    }
  };

  return (
    <DialogContent>
      <>
        <Box
          sx={{
            maxWidth: 800,
            margin: "0 auto",
          }}
        >
          <Box
            sx={{ margin: "32px auto", maxWidth: 400, textAlign: "center" }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <img src={AtticusA} alt="Atticus A" />

            <Typography
              style={{
                fontWeight: 800,
                fontSize: 20,
                color: "#2D2A5C",
              }}
              sx={{ marginTop: 2 }}
            >
              Just a quick reminder from our friends at {context.partner?.title}
            </Typography>
          </Box>
          <Box mb={4}>
            {bulletContent.map((content: { text: string }) => {
              return (
                <Box
                  display="flex"
                  flexDirection="row"
                  mb={1}
                  key={content.text.substring(10)}
                >
                  <Box>
                    <Box sx={bulletSX} />
                  </Box>
                  <Box>
                    <Typography variant="body2">{content.text}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Typography ml={1} mb={1} variant="body2">
            Please read through {context.partner?.title}’s waiver and
            disclosures:
          </Typography>

          {/* START Disclaimer Scrollbox Area */}
          <Box
            style={{
              overflowY: "auto",
              height: 350,
              border: "solid 1px #ccc",
              borderRadius: 5,
              padding: 20,
              color: "rgba(0,0,0,.60)",
            }}
          >
            <ReactMarkdown
              children={markdownString}
              className={mdStyles.reactMarkDown}
            />
          </Box>
          {/* END Disclaimer Scrollbox Area */}

          <Box display="flex" alignItems="center" flexDirection="column" mt={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={checkBoxClicked}
                    color="secondary"
                  />
                }
                label={`I agree to ${context.partner?.title}'s waiver & disclosures.`}
              />
            </FormGroup>
            <Box mt={2} style={{ display: "flex", flexDirection: "column" }}>
              {showErrorText && (
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: atticusColors.red1,
                    marginBottom: "20px",
                  }}
                >
                  Waiver & disclosures are required
                </Typography>
              )}
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className="phatButton"
                component="a"
                onClick={continueClicked}
                style={{ alignSelf: "center" }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    </DialogContent>
  );
};
